import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import SuspenseLoading from './components/SuspenseLoading';
import AuthRoute from './AuthRoute';
const Login = lazy(() => import('./components/Login'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Orders = lazy(() => import('./components/Orders'));
const Kitchen = lazy(() => import('./components/Kitchen'));
const Settings = lazy(() => import('./components/Settings'));
const Home = lazy(() => import('./components/Home'));
const Inventory = lazy(() => import('./components/Inventory'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route path="/login" component={Login} />
          <AuthRoute exact path="/" component={Home} />
          <AuthRoute path="/lookup" component={Dashboard} />
          <AuthRoute path="/orders" component={Orders} />
          <AuthRoute path="/kitchen" component={Kitchen} />
          <AuthRoute path="/inventory" component={Inventory} />
          <AuthRoute path="/settings" component={Settings} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

import { combineReducers } from 'redux';
import { categoriesReducer } from './categories';
import { userReducer } from './user';
import { orderReducer } from './orders';
import { storeReducer } from './store';

export default combineReducers({
  userReducer,
  categoriesReducer,
  orderReducer,
  storeReducer
});

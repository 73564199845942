import {
  FETCH_ORDERS_BOOKED,
  FETCH_ORDERS_DELIVERED,
  FETCH_ORDERS_READY,
  FETCH_ORDERS_DELAYED,
} from '../types';

const initialState = {
  data: [],
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_BOOKED:
      return {
        data: action.payload,
      };
    case FETCH_ORDERS_DELIVERED:
      return {
        data: action.payload,
      };
    case FETCH_ORDERS_READY:
      return {
        data: action.payload,
      };
    case FETCH_ORDERS_DELAYED:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

import {
  UPDATE_STORE_VIEW_SETTING,
  FETCH_STORE_CATEGORIES,
  HANDLE_CHANGE_PRODUCT_ACTIVE,
} from '../types';

const initialState = {
  view: localStorage.getItem('viewmode') || 'opview',
  storeCategories: [],
  storeProducts: [],
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STORE_VIEW_SETTING:
      localStorage.setItem('viewmode', action.payload);
      return { ...state, view: action.payload };
    case FETCH_STORE_CATEGORIES:
      const { categories, products } = action.payload;
      return {
        ...state,
        storeCategories: categories || [],
        storeProducts: products || [],
      };
    case HANDLE_CHANGE_PRODUCT_ACTIVE:
      const { id, value } = action.payload;
      const indexActive = state.storeProducts.findIndex(
        (it) => it.objectId === id
      );
      if (indexActive === -1) return state;
      return {
        ...state,
        storeProducts: state.storeProducts.map((it, index) => {
          if (index === indexActive) {
            return { ...it, is_active: value };
          }
          return it;
        }),
      };
    default:
      return state;
  }
};

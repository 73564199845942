export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_BOOKED = 'FETCH_ORDERS_BOOKED';
export const FETCH_ORDERS_READY = 'FETCH_ORDERS_READY';
export const FETCH_ORDERS_DELIVERED = 'FETCH_ORDERS_DELIVERED';
export const FETCH_ORDERS_DELAYED = 'FETCH_ORDERS_DELAYED';
export const UPDATE_ORDER_STATUS_FINISHED = 'UPDATE_ORDER_STATUS_FINISHED';
export const UPDATE_ORDER_STATUS_DELIVERED = 'UPDATE_ORDER_STATUS_DELIVERED';

export const UPDATE_STORE_PREPARE_TIME = 'UPDATE_STORE_PREPARE_TIME';
export const UPDATE_STORE_VIEW_SETTING = 'UPDATE_STORE_VIEW_SETTING';
export const FETCH_STORE_CATEGORIES = 'FETCH_STORE_CATEGORIES';
export const FETCH_STORE_PRODUCTS = 'FETCH_STORE_PRODUCTS';
export const HANDLE_CHANGE_PRODUCT_ACTIVE = 'HANDLE_CHANGE_PRODUCT_ACTIVE';

import React from 'react';
import './loading.scss';

function SuspenseLoading() {
  return (
    <div id="startingLoader">
      <div className="load">
        <div className="circle1"></div>
        <div className="circle2"></div>
      </div>
    </div>
  );
}

export default SuspenseLoading;
